import React, { useState, useEffect } from 'react';
import { Container, Button, TextField, Box } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext'; // Uppdatera sökvägen beroende på var du placerar AuthContext.js
import axios from './axios';
import { useNavigate } from 'react-router-dom';


export default function LoginForm() {
  const { login } = useAuth(); // Hämta setIsLoggedIn från autentiseringskontexten
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const myVariable = process.env.REACT_APP_SERVER_URL;
  console.log(myVariable); // Loggar ut miljövariabeln
  
  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const loginResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/login`, {
        username,
        password,
      });

      if (loginResponse.data.token) {
        console.log('token från response:', loginResponse.data.token);

        login(loginResponse.data.token); // Kalla på login-funktionen från AuthContext
        console.log('Loggar in...');
        // spara token i en globalt tillgänglig konstant
        navigate('/home');
      } else {
        // Hantera fel här, t.ex. visa ett felmeddelande
        console.log('Login misslyckades');
      }
    } catch (error) {
      console.error('Login error', error);
      // Hantera fel här, t.ex. visa ett felmeddelande
    }
  };

  return (
    
    <div>
      <Container>
        <h2>Logga in</h2>
        <form onSubmit={handleLogin}>
          <Box mb={2}>
            <TextField
              type="text"
              label="Användarnamn"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              sx={{ minWidth: '350px' }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              type="password"
              label="Lösenord"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              sx={{ minWidth: '350px' }}
            />
          </Box>
          <Box mt={2}>
            <Button type="submit" variant="contained">Logga in</Button>
          </Box>
        </form>
      </Container>
    </div>
  );
}
