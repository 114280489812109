// App.js
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Header from './components/Layout/Header';
import Home from './components/Layout/Home';
import Breadcrumbs from './components/Layout/Breadcrumbs';
import Card1 from './components/Layout/Card1';
import ImageUploader from './components/Layout/ImageUploader';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Register from './components/users/Signup'; // Importera din Register-komponent
import LoginForm from './components/users/Login';
import TestPage from './components/Layout/Testpage';
import CreateLog from './components/Layout/CreateLog';
import ShowLog from './components/Layout/ShowLog';
import ShowProfile from './components/Layout/ShowProfile';
import Info from './components/Layout/Info'; // Uppdatera sökvägen om det behövs
import UpdateLog from './components/Layout/UpdateLog';
import Blog from './components/Layout/Blog';
import LogTheme from './components/Layout/LogTheme';
import { useAuth } from './contexts/AuthContext';
import { AuthProvider } from './contexts/AuthContext';
import YearLogs from './components/Layout/YearLogs';
import ChangeUser from './components/users/ChangeUser';
import ChangeProfile from './components/users/ChangeProfile';

function HomePage() {
  const { currentUser } = useAuth();
  return (
    <Box style={{ padding: '20px' }}>
      {currentUser ? <Blog /> : <Info />}
    </Box>
  );
}

export default function LoggApp() {
  return (
    <AuthProvider>
      <LogTheme>
        <div>
          <Router>
            <Header />
            <Routes>
              <Route path="/createlog" element={<CreateLog />} />
              <Route path="/updatelog/:log_id" element={<UpdateLog />} />
              <Route path="/showlog/:log_id" element={<ShowLog />} />
              <Route path="/showprofile/:userid" element={<ShowProfile />} />
              <Route path="/changeprofile/:userid" element={<ChangeProfile />} />
              <Route path="/info" element={<Info />} />
              <Route path="/signup" element={<Register />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginForm />}/>
              <Route path="/logs" element={<YearLogs />} />
              <Route path="/changeuser" element={<ChangeUser />} />
            </Routes>
          </Router>
        </div>
      </LogTheme>
    </AuthProvider>
  );
}
