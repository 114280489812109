import React from 'react';
import { Box, Grid, Card, CardMedia } from '@mui/material';
import LogTheme from './LogTheme';

function Info() {
    return (
        <LogTheme>
            <Box style={{ padding: '20px' }}>
                <h1>Välkommen!</h1>
                <div className="ingress">
                <p>Loggboken är en plats för både segel- och motorbåtsentusiaster. Här kan du skapa loggar, lägga till bilder och beskriva dina upplevelser på vattnet.</p>
                </div>
                <Grid container direction="row-reverse" spacing={4}>
                    <Grid item xs={12} md={6}>
                    <Box display="flex" flexDirection="column" justifyContent="center" style={{ height: '100%' }}>

                        <h2>Privat</h2>
                        <p>All text och bilder som du lägger upp på Loggboken är privata. Ingen annan har åtkomst till dina loggar om du inte själv väljer att dela dem med familj och vänner. Vi värnar om integriteten och vill inte att dina personliga berättelser och bilder ska hamna ute på nätet.</p>
                    </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card elevation={8}>
                            <CardMedia
                                component="img"
                                image="/images/image1.jpg"
                                alt="Beskrivning av bilden"
                                style={{ maxWidth: '95%', height: 'auto', padding: '20px' }}
                            />
                        </Card>

                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Box display="flex" flexDirection="column" justifyContent="center" style={{ height: '100%' }}>
                            <h2>Loggar i PDF-format</h2>
                            <p>Inom kort kommer du även att ha möjligheten att ladda ner dina loggar i PDF-format. Detta gör det enkelt att spara dina äventyr i pappersformat eller skapa din egen personliga bok med dina båtupplevelser.</p>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card elevation={8}>
                            <CardMedia
                                component="img"
                                image="/images/image1.jpg"
                                alt="Beskrivning av bilden"
                                style={{ maxWidth: '95%', height: 'auto', padding: '20px' }}
                            />
                        </Card>
                    </Grid>

                </Grid>
                <Grid container direction="row-reverse" spacing={4}>
                    <Grid item xs={12} md={6}>
                    <Box display="flex" flexDirection="column" justifyContent="center" style={{ height: '100%' }}>

                        <h2>Kontakta oss</h2>
                        <p>För att komma igång, registrera ett konto eller om du har några frågor, kontakta oss på <a href="mailto:info@seglingssajten.se">info@seglingssajten.se</a>. Vi ser fram emot att hjälpa dig att skapa och bevara dina båtupplevelser!</p>
                   </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card elevation={8}>
                            <CardMedia
                                component="img"
                                image="/images/image1.jpg"
                                alt="Beskrivning av bilden"
                                style={{ maxWidth: '95%', height: 'auto', padding: '20px' }}
                            />
                        </Card>
                    </Grid>
                </Grid>

            </Box>
        </LogTheme>
    );
}

export default Info;