import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import LogTheme from './LogTheme';
import {Typography, Box} from '@mui/material';


function Blog() {
    const [logs, setLogs] = useState([]);
    const [isUserIdLoaded, setIsUserIdLoaded] = useState(false);
  
    console.log('Loggar: ', logs);
    const { userId, forceUpdate } = useAuth();
    console.log('userId in Blog.js:', userId);
    useEffect(() => {

        if (userId) {
            setIsUserIdLoaded(true);

            // Hämta loggar för den aktuella användaren
            console.log('Sending userId:', userId);
            fetch(`${process.env.REACT_APP_SERVER_URL}/logs`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-User-Id': userId,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                  // Sortera loggarna baserat på departureDateTime
                  data.sort((a, b) => new Date(a.departureDateTime) - new Date(b.departureDateTime));
                  setLogs(data);
              })
                .catch((error) => console.error('Error fetching logs:', error));
        } else {
          // Rensa logs-staten när userId är null
          setLogs([]);
      }

    }, [userId, forceUpdate]);

    return (
        <LogTheme>
          <div>
            <Box padding={2}>
            <Typography variant="h1">Mina loggar</Typography>
            {Array.isArray(logs) && logs.map(log => (
              <div key={log._id}>
                <Link to={`/showlog/${log._id}`}>
                  <Typography variant="h4">{log.title}</Typography>
                </Link>
                
              </div>
            ))}
            </Box>
          </div>
        </LogTheme>
      );
}

export default Blog;